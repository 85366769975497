import {authService} from "../application/services/auth.service";
import {frontEndV2ExternalLoginUrl} from "../_configuration/api.config";

export const redirectToNewFrontEnd = async () => {

    try {
        const session = await authService.getCurrentSession();
        const accessToken = session.getAccessToken().getJwtToken();
        const refreshToken = session.getRefreshToken().getToken();
        const idToken = session.getIdToken().getJwtToken();
        const awsExp = session.getIdToken().getExpiration();

        // Créer un formulaire dynamiquement
        const form = document.createElement('form');
        form.method = 'post';
        form.action = frontEndV2ExternalLoginUrl;
        console.log('redirecting..', frontEndV2ExternalLoginUrl)

        // Ajouter les champs cachés
        const fields = {
            accessToken,
            refreshToken,
            idToken,
            awsExp
        };

        Object.entries(fields).forEach(([key, value]) => {
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = key;
            input.value = value as string;
            form.appendChild(input);
        });

        // Ajouter le formulaire au document et le soumettre
        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);

    } catch (error) {
        console.error('Erreur lors de la redirection:', error);
        throw error;
    }
};