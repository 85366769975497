import Box from "@mui/material/Box";
import {SearchItem} from "./SearchItem";
import {SegmentSearchBar} from "./SegmentSearchBar";
import {SegmentCountryFilter} from "./SegmentCountryFilter";
import {SegmentProviderFilter} from "./SegmentProviderFilter";
import React from "react";
import {useSegmentBuilderStore} from "../SegmentBuilderStore";
import {SegmentSearchTypeFilter} from "./SegmentSearchTypeFilter";
import {Button, Paper, Typography} from "@mui/material";
import PublicIcon from '@mui/icons-material/Public';
import SearchIcon from '@mui/icons-material/Search';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import TopicOutlinedIcon from '@mui/icons-material/TopicOutlined';

export const SegmentsSearch = () => {
    const searchDone = useSegmentBuilderStore(st => st.searchDone)
    const displaySegmentSelected = useSegmentBuilderStore(st => st.displaySegmentSelected)
    const selectedSegments = useSegmentBuilderStore(st => st.selectedSegments)

    const countries = useSegmentBuilderStore(st => st.countriesList)
    const providers = useSegmentBuilderStore(st => st.providersList)


    return <>
        {!displaySegmentSelected ? (
                <Paper sx={{
                    display: 'flex',
                    gap: '1rem',
                    justifyContent: searchDone ? 'space-between' : 'center',
                    alignItems: 'center',
                    backgroundColor: '#fff',
                    padding: "1rem",
                    paddingBottom: "0",
                    marginTop: "1rem",
                }}>
                    <Box sx={{
                        display: 'flex',
                        gap: '1rem',
                        justifyContent: searchDone ? 'start' : 'center',

                    }}>
                        <>
                            <Box sx={{width: "400px"}}>
                                <SearchItem title={"Search in segment list"} icon={<SearchIcon sx={{color: "#23C6D0"}}/>}>
                                    <SegmentSearchBar/>
                                </SearchItem>
                            </Box>

                            {searchDone && (
                                <SearchItem title={"Search Type"}
                                            icon={<SettingsSuggestOutlinedIcon sx={{color: "#F33992"}}/>}>
                                    <SegmentSearchTypeFilter/>
                                </SearchItem>

                            )}
                            {countries.length > 2 &&
                                <SearchItem title={"Country"} icon={<PublicIcon sx={{color: "#5FCBC4"}}/>}>
                                    <SegmentCountryFilter/>
                                </SearchItem>
                            }
                            {providers.length > 2 &&
                                <SearchItem title={"Provider"} icon={<TopicOutlinedIcon sx={{color: "#A877CF"}}/>}>
                                    <SegmentProviderFilter/>
                                </SearchItem>
                            }
                        </>


                    </Box>


                </Paper>
            ) :
            (
                <Box>
                    <Typography variant="h4" fontSize="16px" sx={{textAlign: 'center'}}>
                        {selectedSegments.length} {selectedSegments.length > 1 ? 'Segments' : 'Segment'} selected
                    </Typography>
                    <Button variant="text" size="small" sx={{display: 'block', margin: 'auto'}}
                            onClick={() => useSegmentBuilderStore.setState({displaySegmentSelected: false})}>
                        Back to search
                    </Button>
                </Box>
            )}
    </>
}