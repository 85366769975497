import {Box, Button, Collapse, InputAdornment, styled, TextField, Typography} from "@mui/material";
import {profilesFormatter} from "_configuration/formaters";
import {PageId} from "application/pages/pages.config";
import {pageUtils} from "application/pages/pages.utils";
import {AUTHUser} from "application/utils/AuthUser";
import {getDspAdditionalInfo, getDspIcon} from "application/utils/dsp.utils";
import {getCurrencySymbol, moneyFormatter} from "application/utils/formatters.utils";
import {EPermission} from "application/utils/granter.utils";
import {StyledCheckbox} from "components/form/CheckBox/StyledCheckBox";
import {useSelectedDspChannels} from "components/hooks/data/useDspChannelsQuery";
import {useUserQuery} from "components/hooks/data/useUserQuery";
import {AppIcon} from "components/ui/AppIcon";
import {AppTip} from "pages/AudienceBuilder/components/AudienceBuilderAppTip";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {useFeatureFlag} from "stores/featureFlags.store";
import {ICompanyUsers} from "types";
import {useShallow} from "zustand/react/shallow";
import {StepDescription, StepTitle} from "..";
import {useAudienceEnablementStore} from "../store/AudienceEnablement.store";
import {SelectionStepFormType} from "../types";
import CheckIcon from '@mui/icons-material/Check';

export const DspSelectionStep = () => {
    const {t} = useTranslation();
    const featuresFlags = useFeatureFlag((st) => st.featureFlags);

    const audienceData = useAudienceEnablementStore(useShallow((state) => state.audienceData));
    const isCpmSetToZero = useAudienceEnablementStore((state) => state.isCpmSetToZero);
    const totalSpentPayOnce = useAudienceEnablementStore((st) => st.totalSpentPayOnce);
    const totalProfilesPayOnce = useAudienceEnablementStore((st) => st.totalProfilesPayOnce);
    const totalProfilesImpressionBasedBuy = useAudienceEnablementStore((st) => st.totalProfilesImpressionBasedBuy);
    const audienceEnablementActions = useAudienceEnablementStore((state) => state.actions);
    const selectionStepForm = useAudienceEnablementStore(useShallow((state) => state.selectionStepForm));

    const {data: channelsActive} = useSelectedDspChannels(audienceData?.account?.id); //to build the link to channel setup is dspitem has error
    const [isImpressionBasedByActive, setIsImpressionBasedByActive] = useState(false);
    const [profilesMinReached, setProfilesMinReached] = useState<boolean>(false); // For Continue button
    const [isImpressionBasedBuySelected, setIsImpressionBasedBuySelected] = useState(false);
    const currency = audienceData.displayCurrency;
    const tableColumns = [
        {
            id: "enablementChannel",
            title: t("dsp.enablementChannel"),
            appTip: null,
            width: "1fr",
            align: "left",
        },
        {
            id: "enablementOption",
            title: t("dsp.enablementOption"),
            appTip: t("dsp.dspTable.enablementOptionTip"),
            width: "1fr",
            align: "left",
        },
        {
            id: "buyerId",
            title: t("dsp.BuyerId"),
            appTip: t("dsp.dspTable.enablementChannelIdTip"),
            width: "110px",
            align: "center",
        },
        {
            id: "maxSize",
            title: t("dsp.maxSize"),
            appTip: t("dsp.dspTable.maxSizeTip"),
            width: "130px",
            align: "center",
        },
        ...(!isCpmSetToZero
            ? [
                {
                    id: "cmp",
                    title: t("dsp.cmp"),
                    appTip: null,
                    width: "110px",
                    align: "center",
                },
            ]
            : []),
        {
            id: "desiredNbProfile",
            title: t("dsp.desiredNbProfile"),
            appTip: null,
            width: "110px",
            align: "center",
        },
        ...(!isCpmSetToZero
            ? [
                {
                    id: "desiredSpend",
                    title: t("dsp.desiredSpend"),
                    appTip: null,
                    width: "110px",
                    align: "center",
                },
            ]
            : []),
        ...(isImpressionBasedByActive === true
            ? [
                {
                    id: "desiredImpressions",
                    title: "Impression based buy",
                    appTip: null,
                    width: "80px",
                    align: "center",
                },
            ]
            : []),
    ];
    const gridTemplate = tableColumns.map((col) => col.width).join(" "); // To fill the grid-template-columns property

    //approval request
    const userQuery = useUserQuery({userId: AUTHUser.userId});
    const userData: ICompanyUsers = userQuery?.data ?? {};
    const brandAssociation = userData?.agentBrandAccountAssociations?.find((brand) => brand.account.id === AUTHUser.currentBrandId);
    const approvalAmount = brandAssociation?.approvalPriceLimit ?? 0;
    const approveRequest = AUTHUser.permissions.accountGrade === EPermission.COMPANYADMIN ? false : Boolean(brandAssociation?.approvalRequired);

    // uncomment to test approval
    //const approvalAmount = 10;
    //const approveRequest = true;

    useEffect(() => {
        if (isCpmSetToZero) {
            audienceEnablementActions.hideStep("Payment");
        }
    }, [isCpmSetToZero, audienceEnablementActions]);

    useEffect(() => {
        let impressionBasedBuyActive = false;
        let impressionBasedBuySelected = false;
        let hasOptionsStep = false;
        selectionStepForm.forEach((dspItem, idx) => {
            if (dspItem.canBeImpressionBasedBuy === true) {
                impressionBasedBuyActive = true;
            }
            if (dspItem.isImpressionBasedBuy === true) {
                impressionBasedBuySelected = true;
            }
            if (dspItem.dspCheckoutParameterGroups?.length > 0 && dspItem.profiles > 0) {
                hasOptionsStep = true;
            }
        });

        if (hasOptionsStep) {
            audienceEnablementActions.displayStep("EnablementOptions");
        } else {
            audienceEnablementActions.hideStep("EnablementOptions");
        }

        setIsImpressionBasedByActive(impressionBasedBuyActive);
        setIsImpressionBasedBuySelected(impressionBasedBuySelected);
        useAudienceEnablementStore.setState({
            approvalAmount: approvalAmount,
            totalSpentPayOnce: calculatePrice(),
            totalProfilesPayOnce: calculateProfiles(false),
            totalProfilesImpressionBasedBuy: calculateProfiles(true),
        });

        //check if profiles min is reached to enable the continue button
        const reach = selectionStepForm.filter((x) => x.profiles > 0 || x.isImpressionBasedBuy).every((x) => x.profiles >= x.minSize);
        setProfilesMinReached(reach);

        //check if there is a dsp with checkout params to activate step
    }, [selectionStepForm]);

    useEffect(() => {
        if (totalSpentPayOnce > approvalAmount && approveRequest && audienceData.audienceStatus !== "APPROVED") {
            audienceEnablementActions.hideStep("Payment");
            audienceEnablementActions.hideStep("Summary");
            audienceEnablementActions.hideStep("Final");
            audienceEnablementActions.displayStep("Approval");
        } else {
            audienceEnablementActions.displayStep("Payment");
            audienceEnablementActions.displayStep("Summary");
            audienceEnablementActions.displayStep("Final");
            audienceEnablementActions.hideStep("Approval");
        }
        if (totalSpentPayOnce === 0) {
            audienceEnablementActions.hideStep("Payment");
        }
    }, [totalSpentPayOnce, totalProfilesImpressionBasedBuy]);

    const getDspItemId = (code: string) => {
        if (channelsActive) {
            const item = channelsActive?.find((channel) => channel.dspChannel.code === code);
            return item?.id;
        }
    };

    const handleChange = (name: string, dspItem: SelectionStepFormType, value: any) => {
        switch (name) {
            case "profiles":
                const profilesVal = value.replaceAll(",", "");
                const ve = profilesVal > dspItem.maxSize ? dspItem.maxSize : profilesVal;
                const profiles = +Number(ve);
                const profilesValues = {
                    profiles: profiles,
                    profilesDisplay: profiles > 0 ? profilesFormatter.format(profiles) : "",
                    desiredSpend: +Number((ve * (dspItem.cpmConverted ?? 1)) / 1000).toFixed(2),
                    desiredSpendDisplay: profiles > 0 ? moneyFormatter((profiles * (dspItem.cpmConverted ?? 1)) / 1000, {hideCurrency: true}) : "",
                };
                audienceEnablementActions.updateSelectionFormStep(dspItem.code, profilesValues);
                if (dspItem.mustBeImpressionBasedBuy) {
                    if (profilesValues.profiles > 0) {
                        handleChange("isImpressionBasedBuy", dspItem, true)

                    } else {
                        handleChange("isImpressionBasedBuy", dspItem, false)
                    }
                }
                break;
            case "desiredSpend":
                const spendVal = value.replaceAll(",", "");
                const speMax = Number((dspItem.cpmConverted * (dspItem.maxSize ?? 1)) / 1000);
                const finalVal = Number(spendVal < speMax ? spendVal : speMax);
                const desiredProfiles = +Number(finalVal / (dspItem.cpmConverted ?? 1000)) * 1000;

                const spendValues = {
                    desiredSpend: +finalVal.toFixed(2),
                    desiredSpendDisplay: +finalVal > 0 ? +finalVal.toFixed(2) : "",
                    profiles: desiredProfiles,
                    profilesDisplay: desiredProfiles > 0 ? profilesFormatter.format(desiredProfiles) : "",
                };
                audienceEnablementActions.updateSelectionFormStep(dspItem.code, spendValues);

                break;
            case "isImpressionBasedBuy":
                const isIBB = {
                    isImpressionBasedBuy: value,
                };
                audienceEnablementActions.updateSelectionFormStep(dspItem.code, isIBB);

                break;
        }
    };

    const calculatePrice = (onlyImpressionBasedBuy: boolean = false) => {
        let totalSpent: number = 0;
        selectionStepForm.forEach((dspLine) => {
            if (onlyImpressionBasedBuy) {
                if (dspLine.isImpressionBasedBuy) {
                    return 0;
                }
            } else {
                if (!dspLine.isImpressionBasedBuy) {
                    totalSpent = totalSpent + +dspLine?.desiredSpend;
                }
            }
        });
        return totalSpent;
    };

    const calculateProfiles = (onlyImpressionBasedBuy: boolean = false) => {
        let totalProfiles: number = 0;
        selectionStepForm.forEach((dspLine) => {
            if (onlyImpressionBasedBuy) {
                if (dspLine.isImpressionBasedBuy) {
                    totalProfiles = totalProfiles + +dspLine?.profiles;
                }
            } else {
                if (!dspLine.isImpressionBasedBuy) {
                    totalProfiles = totalProfiles + +dspLine?.profiles;
                }
            }
        });
        return totalProfiles;
    };

    return (
        <>
            <StepTitle>Select Channels</StepTitle>
            <StepDescription>
                Select which channels you would like to enable audiences on and define the size of the audience, how
                much you’d like to spend on the audience
                and/or if you’d like to pay for the audience up-front or by impression.
            </StepDescription>
            <DspTable>
                <DspTableRow gridTemplate={gridTemplate} header={true}>
                    {tableColumns.map((col) => {
                        return (
                            <Box key={col.id} width={col.width}
                                 sx={{display: "flex", justifyContent: col.align, gap: "0.25rem"}}>
                                <Typography sx={{
                                    textAlign: col.align,
                                    fontSize: "12px",
                                    fontWeight: "bold"
                                }}>{col.title}</Typography>
                                {col.appTip && <AppTip title={col.title} text={col.appTip}/>}
                            </Box>
                        );
                    })}
                </DspTableRow>

                {selectionStepForm.map((dspItem) => {
                    const additionnalInfo = getDspAdditionalInfo(dspItem.code);
                    const errProfiles = Boolean(dspItem?.profiles) && dspItem.minSize > dspItem?.profiles;
                    return (
                        <DspTableRow key={dspItem.code} gridTemplate={gridTemplate} data-cy={`dsp-${dspItem.code}`}>
                            <Box>
                                <Box sx={{display: "flex", alignItems: "center", gap: "0.5rem"}}>
                                    <AppIcon fontSize={"small"} icon={getDspIcon(dspItem.activationPlatformCode)}/>
                                    <Typography sx={{fontWeight: "bold", fontSize: "14px"}}
                                                data-cy={"activationPlatform"}>
                                        {dspItem.activationPlatform}
                                    </Typography>
                                </Box>
                                {dspItem.seatName !== "Untitled" && dspItem.seatName.length > 2 && (
                                    <Typography className="color-tech-grey" ml={3} data-cy={"seatName"}>
                                        {dspItem.seatName}
                                    </Typography>
                                )}
                            </Box>

                            <Box>
                                <Box sx={{display: "flex", alignItems: "center", gap: "0.5rem"}}>
                                    <AppIcon fontSize={"small"} icon={getDspIcon(dspItem.code)}/>
                                    <Typography sx={{fontWeight: "bold", fontSize: "12px"}}
                                                data-cy={"activationPlatform"}>
                                        {additionnalInfo?.name ?? dspItem.name}
                                    </Typography>
                                </Box>
                                {additionnalInfo?.restriction && (
                                    <Box sx={{display: "flex", alignItems: "center", gap: "0.5rem", marginTop: "5px"}}>
                                        <Typography className="color-tech-grey" style={{fontStyle: "italic"}}>
                                            {additionnalInfo?.restriction}
                                        </Typography>
                                        {additionnalInfo?.restrictionDetails && (
                                            <AppTip title={additionnalInfo?.restriction}
                                                    text={additionnalInfo?.restrictionDetails}/>
                                        )}
                                    </Box>
                                )}
                                {dspItem.hasError && (
                                    <Box pt={1}>
                                        <Typography color="error">
                                            Error, please check your channel setup{" "}
                                            <Link
                                                to={pageUtils.getPagePathById(PageId.channelsSetupEdit, {
                                                    accountId: audienceData.account.id,
                                                    channelId: getDspItemId(dspItem.activationPlatformCode),
                                                })}
                                            >
                                                on this page
                                            </Link>
                                        </Typography>
                                    </Box>
                                )}
                            </Box>

                            <Box textAlign={"center"}>
                                <Typography data-cy={"advertiserId"} noWrap={true}>
                                    {dspItem.advertizerId}
                                </Typography>
                            </Box>

                            <Box textAlign={"center"}>
                                {dspItem.minSize > dspItem.maxSize ? (
                                    "< Minimum"
                                ) : (
                                    <Typography
                                        data-cy={"maxSize"}>{profilesFormatter.format(dspItem.maxSize)}</Typography>
                                )}
                            </Box>

                            {!isCpmSetToZero && (
                                <Box textAlign={"center"}>
                                    <Typography
                                        data-cy={"cpm"}>{moneyFormatter(dspItem.cpmConverted, {currency: currency})}</Typography>
                                </Box>
                            )}

                            <Box style={{textAlign: "center"}}
                                 data-cy={dspItem.minSize > dspItem.maxSize ? "profiles-not-selectable" : "profiles-selectable"}>
                                <TextField
                                    name={"profiles"}
                                    value={dspItem.profilesDisplay !== 0 ? dspItem.profilesDisplay : null}
                                    disabled={Boolean(dspItem?.hasError) || dspItem.minSize > dspItem.maxSize}
                                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                        handleChange("profiles", dspItem, event.target.value);
                                    }}
                                    placeholder={dspItem.minSize > dspItem.maxSize ? "< Minimum" : "Min. " + profilesFormatter.format(dspItem.minSize)}
                                    inputProps={{
                                        style: {textAlign: "center"},
                                    }}
                                    InputProps={{
                                        error: errProfiles,
                                        sx: {
                                            fontSize: "10x",
                                            borderRadius: 20,
                                            color: errProfiles ? "res" : "inherit",
                                            border: "1px solid " + errProfiles ? "res" : "transparent",
                                        },
                                    }}
                                    sx={{fontSize: "10x", borderRadius: 0, textAlign: "center"}}
                                    data-cy={"profiles"}
                                />
                            </Box>

                            {!isCpmSetToZero && (
                                <Box>
                                    {!dspItem?.isImpressionBasedBuy && !dspItem?.mustBeImpressionBasedBuy && (
                                        <TextField
                                            name={"desiredSpend"}
                                            value={dspItem.desiredSpendDisplay !== 0 ? dspItem.desiredSpendDisplay : null}
                                            placeholder={
                                                dspItem.minSize > dspItem.maxSize
                                                    ? "< Minimum"
                                                    : "Min. " + moneyFormatter((dspItem.minSize * (dspItem.cpm ?? 1)) / 1000, {currency: currency})
                                            }
                                            disabled={Boolean(dspItem?.hasError) || dspItem.minSize > dspItem.maxSize}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                handleChange("desiredSpend", dspItem, event.target.value);
                                            }}
                                            sx={{fontSize: "10x", borderRadius: 0, textAlign: "center"}}
                                            inputProps={{style: {textAlign: "center"}}}
                                            InputProps={{
                                                sx: {textAlign: "center"},
                                                startAdornment: (
                                                    <InputAdornment position="start" sx={{pl: "10px", mr: "0"}}>
                                                        {getCurrencySymbol(currency)}
                                                    </InputAdornment>
                                                ),
                                            }}
                                            data-cy={"desiredSpend"}
                                        />
                                    )}
                                </Box>
                            )}

                            {isImpressionBasedByActive && (
                                <Box style={{width: "80px", textAlign: "center"}}>

                                    {dspItem?.canBeImpressionBasedBuy && dspItem.cpm !== 0 && !dspItem.mustBeImpressionBasedBuy && (
                                        <StyledCheckbox
                                            checked={dspItem.isImpressionBasedBuy}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange("isImpressionBasedBuy", dspItem, event.target.checked)}
                                            inputProps={{"aria-label": "controlled"}}
                                            data-cy={"isImpressionBasedBuy"}
                                            disabled={Boolean(dspItem?.hasError) || dspItem.minSize > dspItem.maxSize}
                                        />
                                    )}
                                    {dspItem.isImpressionBasedBuy && dspItem.mustBeImpressionBasedBuy && (
                                        <CheckIcon sx={{color: '#00B5E2'}} fontSize={'small'}/>
                                    )
                                    }
                                </Box>
                            )}
                        </DspTableRow>
                    );
                })}

                <Collapse orientation="vertical" in={isImpressionBasedBuySelected}>
                    <DspTableRow mt={2} gridTemplate={gridTemplate} total={true}>
                        <Box textAlign={"right"} fontWeight={"bold"}
                             sx={{gridColumnStart: "1", gridColumnEnd: isCpmSetToZero ? "5" : "6"}}>
                            Total impression based buy
                        </Box>

                        <Box textAlign={"center"} data-cy="total-impression-based-buy">
                            {!Boolean(totalProfilesImpressionBasedBuy) ? "..." : profilesFormatter.format(totalProfilesImpressionBasedBuy ?? 0)}
                        </Box>
                        {!isCpmSetToZero && <Box textAlign={"center"}>...</Box>}
                    </DspTableRow>
                </Collapse>

                <DspTableRow mt={isImpressionBasedBuySelected ? 0 : 2} gridTemplate={gridTemplate} total={true}>
                    <Box textAlign={"right"} fontWeight={"bold"}
                         sx={{gridColumnStart: "1", gridColumnEnd: isCpmSetToZero ? "5" : "6"}}>
                        Total {isImpressionBasedBuySelected && "pay once"}
                    </Box>
                    <Box textAlign={"center"} data-cy="total-payonce">
                        {!Boolean(totalProfilesPayOnce) ? "..." : profilesFormatter.format(totalProfilesPayOnce ?? 0)}
                    </Box>
                    {!isCpmSetToZero && (
                        <Box
                            textAlign={"center"}>{!Boolean(totalSpentPayOnce) ? "..." : moneyFormatter(totalSpentPayOnce ?? 0, {currency: currency})}</Box>
                    )}
                </DspTableRow>
            </DspTable>
            <Box textAlign={"center"} mt={1}>
                <Button
                    disabled={totalProfilesImpressionBasedBuy + totalProfilesPayOnce === 0 || !profilesMinReached}
                    onClick={audienceEnablementActions.goToNextStep}
                    variant={"contained"}
                    value={"Continue"}
                    data-cy={"continue-btn"}
                >
                    {t("dsp.continue")}
                </Button>
            </Box>
        </>
    );
};

export const DspTableRow = styled(Box)((props: { gridTemplate: string; header?: boolean; total?: boolean }) => ({
    display: "grid",
    gridTemplateColumns: `${props.gridTemplate}`,
    gap: "1rem",
    paddingBlock: "1rem",
    paddingInline: "0.5rem",
    borderBottom: props.total ? "none" : "1px solid #E0E0E0",
    width: "100%",
    alignItems: "center",
    "&:last-child": {
        borderBottom: "none",
    },
    "&:hover": {
        backgroundColor: props.header || props.total ? "inherit" : "#fafafa",
    },
    transition: "all 0.2s ease-in-out",
    fontSize: props.total ? "16px" : "inherit",
}));

export const DspTable = styled(Box)(() => ({
    width: "100%",
}));
